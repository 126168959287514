<template>
    <div 
        v-if="status.length" 
        class="status_list">
        <div 
            v-for="(item, index) in status" 
            :key="item.code"
            class="item">
            <div 
                class="wrap"
                :class="[checkActive(item, index), item.color, isAuthor && 'cursor-pointer']"
                @click="changeStatus(item)">
                <a-badge :color="item.color" />
                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        task: {
            type: Object,
            required: true
        },
        isAuthor: {
            type: Boolean,
            default: false
        }
    },  
    computed: {
        ...mapState({
            statusList: state => state.task.statusList
        }),
        status() {
            return this.statusList?.[this.task.task_type]?.length ? this.statusList[this.task.task_type] : []
        },
        currentIndex() {
            const index = this.status.findIndex(f => f.code === this.task.status.code)
            if(index !== -1)
                return index
            else
                return null
        },
        checkStatus() {
            if(this.task.status.is_complete && this.task.status.code === 'failed')
                return 'select failed'
            else if(this.task.status.is_complete)
                return 'select success'
            else
                return 'select'
        }
    },
    methods: {
        checkActive(item, index) {
            if(this.task?.status?.code === item.code)
                return this.checkStatus
            else {
                return index <= this.currentIndex && this.checkStatus
            }
        },
        async changeStatus(status) {
            if(this.isAuthor) {
                try {
                    await this.$store.dispatch('task/changeStatus', {
                        task: this.task, 
                        status
                    })
                } catch(e) {
                    this.$message.error(this.$t('error'))
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.status_list{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .item{
        width: 100%;
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;
        @media (max-width: 1200px) {
            font-size: 12px;
        }
        &:not(:last-child){
            margin-right: 13px;
        }
        .wrap{
            background: #eee;
            color: var(--text);
            display: flex;
            align-items: center;
            border-radius: var(--borderRadius) 0 0 var(--borderRadius);
            height: 32px;
            line-height: 26px;
            padding: 0 15px 0 15px;
            position: relative;
            margin-right: 10px;
            text-decoration: none;
            -webkit-transition: color 0.2s;
            width: 100%;
            &::after{
                background: #fff;
                border-bottom: 16px solid transparent;
                border-left: 10px solid #eee;
                border-top: 16px solid transparent;
                content: '';
                position: absolute;
                left: 100%;
                top: 0;
            }
            &.select{
                background: var(--blue);
                color: #fff;
                &::after{
                    border-left: 10px solid var(--blue);
                }
                &.success{
                    background: #89d961;
                    &::after{
                        border-left: 10px solid #89d961;
                    }
                }
                &.failed{
                    background: #f5222d;
                    &::after{
                        border-left: 10px solid #f5222d;
                    }
                }
            }
        }
    }
}
</style>