export const priorityList = [
    {
        name: 'Очень низкий',
        value: 0,
        color: 'success',
        i18n: 'ultralow'
    },
    {
        name: 'Низкий',
        value: 1,
        color: 'success',
        i18n: 'low'
    },
    {
        name: 'Средний',
        value: 2,
        color: 'warning',
        i18n: 'middle'
    },
    {
        name: 'Высокий',
        value: 3,
        color: 'error',
        i18n: 'tall'
    },
    {
        name: 'Очень высокий',
        value: 4,
        color: 'error',
        i18n: 'veryhigh'
    },
]
export const replaceFlatArray = (array) => {
    let newArray = []
    array.forEach(item => {
        newArray.push(item)
        if (item.children && item.children.length) {
            newArray = newArray.concat(replaceFlatArray(item.children))
        }
    })
    return newArray
}
