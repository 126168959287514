export default {
    data() {
        return {
            takeLoader: false
        }
    },
    methods: {
        mobileTakeTask(task) {
            this.$confirm({
                title: 'Вы действительно хотите взять задачу?',
                closable: true,
                maskClosable: true,
                cancelText: 'Отмена',
                okText: 'Взять',
                zIndex: 99999,
                onOk: async () => {
                    try {
                        this.takeLoader = true
                        await this.$store.dispatch('task/takeAuctionTask', { task })
                        this.$message.success("Задача Ваша")
                    } catch(error) {
                        this.$message.error(this.$t('task.error'))
                        console.error(error)
                    } finally {
                        this.takeLoader = false
                    }
                }
            })
        },
        async takeTask(task) {
            try {
                this.takeLoader = true
                await this.$store.dispatch('task/takeAuctionTask', { task })
                this.$message.success("Задача Ваша")
            } catch(error) {
                this.$message.error(this.$t('task.error'))
                console.error(error)
            } finally {
                this.takeLoader = false
            }
        }
    }
}